import { MIN_PASSWORD_LENGTH, EMAIL_REGEXP } from './constants';

export function validatePassword(password) {
  return (
    password.length >= MIN_PASSWORD_LENGTH &&
    password.match(/[A-Z]+/) &&
    password.match(/[a-z]+/) &&
    password.match(/[\d]+/) &&
    password.match(/[#?!@$%^&*-]+/)
  );
}

// We consider 'undefined' to be valid here so we don't
// mark untouched form fields as invalid.
export function validateName(name) {
  return typeof name === 'undefined' || (name && /^[a-zA-Z\s](?:['\-a-zA-Z\s]*)?$/.test(name));
}

export function validateEmail(email) {
  return typeof email === 'undefined' || (email && EMAIL_REGEXP.test(email));
}

export function validatePhone(phone) {
  return typeof phone === 'undefined' || (phone && /^\+1\d{10}$/.test(phone));
}
